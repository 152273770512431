import React, {useState} from 'react'
import Navigation from '../Navigation'
import logo from '../../images/timeless-logo.png';
import {cl} from '../../helpers'


const TimelessHeader = props => {

	return(
	<header id="topHeader" className="d-flex flex-column-reverse align-items-center p-5">
		<div className="col col-md-7 col-lg-5">
			<h1 className="text-light"><img id="mainLogo"  src={logo} alt="Timeless Landscaping"/></h1>
		</div>
		<Navigation/>
	</header>
	)
}


export default TimelessHeader