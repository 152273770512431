import React, { useState,useEffect } from 'react';
import axios from 'axios'
import {cl} from './helpers'
import { DateTime } from 'luxon'
import {Row,Container,Form,InputGroup,InputGroupText,Input,Button,Modal} from 'reactstrap'
import TimelessHeader from './comps/TimelessHeader'
import Loading from './comps/Loading'
import WebFont from 'webfontloader'
import {FaCaretDown,FaCaretUp} from 'react-icons/fa'

import './css/App.css';

WebFont.load({
  google: {
    families: ['Lato:300,400,700','sans-serif']
  }
})


//import Home from './routes'


const App = function(props) {

  const [toggles,setToggles] = useState({})
  const startYear = 2007
  const yearsInBusiness = DateTime.local().year - startYear

  const toggleDesc = e => {
    e.preventDefault()

    const {toggleTarget} = e.currentTarget.dataset

    const newToggleState = !!!toggles[toggleTarget]


    setToggles({...toggles,[toggleTarget]:newToggleState})

  }

    return <div id="App">
        <TimelessHeader/>
        <section className="bgLight d-flex flex-column align-items-center p-5 bg-tl-light text-light font-weight-thin">
          <h2>P3 Project Consulting</h2>
          <div>
            <h2>Environmental Consulting <span className="btn text-tl-dark" onClick={toggleDesc} data-toggle-target="env">{!!toggles['env']?<FaCaretUp/>:<FaCaretDown/>}</span></h2>
            <blockquote data-toggle="env" className={`text-center ${!!!toggles['env']?'d-none':''}`}>Reforestation, Remediation, Naturalization</blockquote>
          </div>
          <h2>Landscape Architecture/Design</h2>
          <h2>Estate Home Project Management</h2>
          <h2>Project Estimation</h2>
        </section>
        <Container className="col-12 col-md-8 col-lg-6 p-5 my-5">
          <h3 className="mb-4">About Timeless Landscaping</h3>
          <p>Timeless Landscaping has been serving the Edmonton area for {yearsInBusiness} years.</p>
          <p>2020 marked a transitional year ending a long era of offering direct landscape construction services. We have narrowed our focus to consulting, project management, and landscape architecture/design services.</p>
          <p>We are proud, and very grateful, to have worked with so many wonderful clients over the years, including the TransEd LRT project for the City of Edmonton.</p>
          <p>Our joy remains centered around projects that are timeless in form, function, and quality&hellip; and our continued aim is to drive the success of projects through a detail oriented, well researched, and client focused approach!</p>
        </Container>
        <section className="p-5 bg-tl-light text-light">
          <Container className="d-flex flex-column align-items-center" id="contact">
            <h3>Contact Form</h3>
            <ContactForm>
              <HOCInput name="n" label="Name" type="text" placeholder="First and Last" />
              <HOCInput name="r" label="reply-to" type="hidden" value="info@timelesslandscaping.com"/>
              <HOCInput name="e" label="Email" type="text" placeholder="yourEmail@email.com"/>
              <HOCInput name="s" label="Subject" type="select" value="TL - Estimate Inquiry">
                <option value="TL - Lanscape Architecture/Design Inquiry">Architecture/Design Inquiry</option>
                <option value="TL - Consulting Inquiry">Consulting Inquiry</option>
                <option value="TL - General Inquiry">General Inquiry</option>
              </HOCInput>
              <HOCInput name="m" label="Message" type="textarea" placeholder="We're looking forward to hearing from you!"/>
            </ContactForm>
          </Container>
        </section>
        <footer className="bg-tl-dark p-5 d-flex text-light">
          <p>&copy;{new Date().getFullYear()} - Timeless Landscaping</p>
        </footer>
      </div>
  }

const ContactForm = props => {
  const [formState,setFormState] = useState({})
  const [sending,setSending] = useState(false)
  const [done,setDone] = useState(false)
  const [sendError,setSendError] = useState(false)

  const reset = () => {
    setSending(false)
    setDone(false)
    setSendError(false)
  }
  const updateFormState = newState => setFormState(oldState=>{return {...oldState,...newState}})
  const submitForm = e => {
    e.preventDefault()
    const valid = Object.entries(formState).reduce((acc,curr)=>{
      return curr[1]!=='' && curr[1] && acc 
    },true)

    if(valid){
      setSending(true)
    }else{
      alert('Invalid form entries. Please complete all fields carefully!')
    }
  }

  useEffect(()=>{
    if(sending){
      const message=`Message from ${formState.n}: ${formState.m}`

      axios.post('https://api.timelesslandscaping.com/contact',{...formState,m:message})
      .then(res=>{
        if(res.data.id){
          setSending(false)
          setFormState({}) 
          setDone(true) 
        }else{
          setSending(false)
          setSendError(res)
        }
        
      })
      .catch(err=>{
        cl(err)
          setSending(false)
          setSendError(err)
      })

    }
  },[sending,formState])

  useEffect(()=>{
    React.Children.forEach(props.children,child=>updateFormState({[child.props.name]:child.props.value||''}))
  },[props.children])

  return (

  sendError?
    <div>
      <h3>There was an error</h3> 
      <div className="d-flex flex-row justify-content-between">
        <Button color="tl-dark" onClick={()=>setSendError(false)}>Try Again</Button>
        <Button color="tl-red" onClick={reset}>Reset Form</Button>
      </div>
    </div>
  :

  done?
    <div>
      <h3 className="text-center">Received!</h3>
      <p>Thank you for your message, we will respond as soon as we can.</p>
      <Button color="tl-dark" onClick={reset}>Reset Form</Button>

    </div>
  :
  <Form onSubmit={submitForm} >
    {React.Children.map(props.children,(Child,index)=>{
      let {value} = formState[Child.props.name]||''
      
      return React.cloneElement(Child,{changeHandler:updateFormState,value,className:"my-3"})
    })}
    <Button color="tl-dark" type="submit" disabled={sending}>{`${sending?'Sending...':'Send Message'}`}</Button>
    <WorkingOverlay test={sending}/>
  </Form>
  )
}




const HOCInput = props => {
  const {name,label,type,value,changeHandler,placeholder,className,children,text} = props

  if(type==='select' && value===''){

  }

  const onChange = e => {
    changeHandler({[name]:e.target.value})
  }


  return  type==='hidden'?
          <Input placeholder={placeholder || ''} {...{type,value,onChange,className}} />
          :
          <InputGroup {...{className}}>
              <InputGroupText>{label.toUpperCase()}</InputGroupText>
            <Input onLoad={changeHandler} placeholder={placeholder || ''} {...{type,value,onChange}}>{children}</Input>

          </InputGroup>

}


const WorkingOverlay = props => {
  const {test} = props
  const Output = ()=> test?<Loading />:null
  return <Output/>
}

export default App;
