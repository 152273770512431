import React, {Fragment,useState} from 'react'
import {ButtonGroup,Button} from 'reactstrap'
import {FaBars} from 'react-icons/fa'
import {cl,cloneChildrenWithProps} from '../../helpers'


const Navigation = props =>{
	const [menuOpen,setMenuOpen] = useState(false)
	const toggleMenuOpen = () => setMenuOpen(!menuOpen)

	return(
		<div className="text-light align-self-end">		
			<h3><FaBars className={`menu-${menuOpen?'open':'closed'}`} onClick={toggleMenuOpen}/></h3>
				<div 
				className="bgDark position-absolute rounded-left" 
				style={{
					right: 0,
					marginTop: '15px',
					width:'250px',
					transform:`translate(${menuOpen?0:'260px'})`,
					transition:'transform 0.45s ease-in'
				}}
			>

					<ButtonGroupHoc 
						vertical 
						className="rounded-left" 
						style={{
							width:'100%',boxShadow:'-7px 10px 7px 3px rgba(0,0,0,0.3)'
						}}
						closeMenu={()=>setMenuOpen(false)}
					>
						<NavButton to="/" >Home</NavButton>
						{/*<NavButton to="about" >About</NavButton>
						<NavButton to="gallery" >Gallery</NavButton>
						<NavButton to="services" >Services</NavButton>*/}
						<NavButton to="#contact" >Contact</NavButton>
					</ButtonGroupHoc>
			</div>
		</div>
	)
}


const ButtonGroupHoc = props => {
	const {children,closeMenu,...restProps} = props
	return(
	<ButtonGroup {...restProps} children={cloneChildrenWithProps(children,{closeMenu})}/>
	)
}

const NavButton = props => {
	const [stateLocation,setStateLocation] = useState('')
	const {to,closeMenu,children,...restProps} = props
	return 	<Button onClick={closeMenu} size="lg" color="tl-dark" tag="a" active={stateLocation===to} href={to}>{children}</Button>

}
export default Navigation