import React from 'react'
export const cl = console.log

// clone props into kids
// comments to remember how this works later
// children (children property from parent element. specified at call)
// new props: object with new properties to be spread into children props
export const cloneChildrenWithProps = (children,newProps) => {

	// map over children
	return React.Children.map(children,(child,index) => 

		// return a clone of the child with:
		React.cloneElement(
			child,
			{	
				// the newProps spread in!
				...newProps,
				index,
			}		
		)
	)
}